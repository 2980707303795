//
//
//
//
//
//
//
//
//
//

import SearchRecommendationProducts from "@/components/pages/search/SearchRecommendation/SearchRecommendationProducts";
import SearchRecommendationWords from "@/components/pages/search/SearchRecommendation/SearchRecommendationWords";
export default {
    name: "SearchRecommendation",
    components: {SearchRecommendationWords, SearchRecommendationProducts},
    props: {
        query: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            words: null,
            products: []
        }
    },
    computed: {
        link() {
            const url = `${this.localePath('/search')}${this.$i18n.locale === 'ua'? '' : `/${this.$i18n.locale}`}`
            if (this.query.length >= 3) {
                return `${url}?s=${this.query}`
            } else {
                return `/search-empty?s=${this.query}`
            }
        },
        hasResults() {
            return Boolean(this.products?.length) || Boolean(this?.words?.items?.length)
        },
        currentLang() {
            return this.$i18n.locale;
        },
        searchLink() {
            return this.currentLang !== 'ua' ? `/search/${this.currentLang}?s=${this.query}` : `/search?s=${this.query}`
        }
    },
    methods: {
        async searchQuery() {
            await this.$axios.get(`${API.main}/search/suggester/recommended`, {
                params: {
                    searchString: this.query
                }
            }).then(function (response) {
                this.products = (response?.data ?? [])
            }.bind(this)).catch();

            await this.$axios.get(`${API.main}/search/suggester`, {
                params: {
                    searchString: this.query,
                    limit: 10
                }
            }).then(function (response) {
                this.words = (response?.data ?? [])
            }.bind(this)).catch()

        },
        closePopup() {
            this.$emit('close')
        }
    },
    watch: {
        query: {
            async handler(val, oldVal) {
                if (val.length >= 3) {
                    await this.searchQuery();
                }
            },
            immediate: true
        }
    },
    beforeDestroy() {
        this.words = null;
        this.products = [];
    }
}
