import {mapGetters} from 'vuex'
import Bus from "@/plugins/VPopup/utils/bus";
import SubscribePopup from "@/components/popups/SubscribePopup/index";

export default {
    data() {
        return {
            isPopUpOpened: false,
            formSubscribeId: 'subscription_form',
            popupCookie: false,
            intervalShow: null
        }
    },
    computed: {
        ...mapGetters({
            pageType: 'dynamicPage/pageType',
            openedFilters: 'catalogStore/getOpenedFilters',
            mobileMenuOpened: 'header/getMobileMenuStatus',
            openTalkMe: 'user/getOpenTalkMeStatus',
            isSubscribed: 'user/isSubscribed',
        }),
        routePath() {
            return this.$route.fullPath.split('/')
        },
        blockedRoutes() {
            return [
                'account', 'help', 'time-out', 'favorites',
                'cart', 'delivery', 'payment', 'confirmation',
                'login', 'registration'
            ]
        },

        inBlockedPage() {
            return this.routePath.length && !!this.blockedRoutes.find(i => i === this.routePath[1]);
        },
    },
    methods: {
        showSubscribe() {
            if(!process.browser){
                return
            }
            this.popupCookie = sessionStorage.getItem('canShowSubscribe') ? sessionStorage.getItem('canShowSubscribe') : false;
            if ( !this.inBlockedPage && !(this.route === 'dynamicPage' && this.pageType === 'pdp') ) {
                this.intervalShow = setTimeout(function (){
                    if ( !this.isPopUpOpened && !this.openedFilters.length && !this.mobileMenuOpened && !this.openTalkMe && !this.isSubscribed && +this.popupCookie === 1 ) {
                        this.openSubscribePopup()
                    } else if (this.popupCookie === false) {
                        sessionStorage.setItem('canShowSubscribe', 1);
                    }
                }.bind(this), 5000)
            }
        },
        async openSubscribePopup() {
            await this.$store.dispatch(`forms/loadFormByIdWithData`, {formId: this.formSubscribeId, data: {source: 'pop_up'}});
            this.$popups.close();
            this.$popups.open({
                component: SubscribePopup,
                props: {
                    formId: this.formSubscribeId,
                    source: 'pop_up'
                }
            })
        }
    },
    watch: {
        routePath: {
            handler() {
                clearTimeout(this.intervalShow)
                this.showSubscribe()
            }
        }
    },
    created() {
        if(!process.browser) {
            return
        }

        this.showSubscribe();
        Bus.$on('opened', data => {
            this.isPopUpOpened = true
        });
        Bus.$on('closed', data => {
            this.isPopUpOpened = false
        });
        Bus.$on('destroyed', data => {
            this.isPopUpOpened = false
        });
    },
}
