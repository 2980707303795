//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import icons from "~/assets/img/icons";
import { mapActions, mapMutations, mapGetters } from 'vuex';
import debounce from '~/utils/_debounce';
import searchRequestEvent from '~/assets/js/eSputnik/searchRequestEvent';
import breakpointsListMixin from '~/mixins/breakpointsList.mixin';
import SearchProposition from '@/components/pages/search/SearchProposition';
import SearchRecommendation from '@/components/pages/search/SearchRecommendation/index';
import SearchPopup from './SearchPopup';

const debouncedEsEvent = debounce(searchRequestEvent,500);

export default {
    name: "SearchInput",
    components: {
        SearchProposition,
        SearchRecommendation,
        SearchPopup
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        loading: {
            type: Boolean,
            default: false
        },
        isHeader: {
            type: Boolean,
            default: false
        }
    },
    mixins: [
        breakpointsListMixin,
    ],
    data() {
        return {
            icons,
            lsFieldName: 'searchPage',
            searchHistory: [],
            isPopUp: false,
            input: '',
            focused: false,
            showPropositions: false,
            propositionResult: {},
        }
    },
    computed: {
        ...mapGetters({
            getHints: 'search/getHints'
        }),
        showHistory() {
            return !!(this.searchHistory.length && this.searchHistory.length > 0 && !this.showCloseIcon);
        },
        showHints() {
            return this.getHints.length && this.getHints.length > 0;
        },
        showPopUp() {
            if (this.isPopupFoolSize) return this.isPopUp || (this.showPropositions && !this.tabletLandscapeUp);
            return this.isPopUp && (this.showHistory || this.showHints);
        },
        popUpItems() {
            //TODO change after create API
            const hintsList = this.getHints.map(item => item.title);
            return this.showHistory ? this.searchHistory : hintsList;
        },
        showCloseIcon() {
            return this.input && this.input.length > 0;
        },
        showSearchIcon() {
            return !this.loading && !(this.showCloseIcon && this.isHeader);
        },
        showLoadingIcon() {
            return !this.isHeader && this.loading;
        },
        isPopupFoolSize() {
            return !this.tabletLandscapeUp && this.isHeader
        },
        nowRoute() {
            return this.$route.path
        },
        searchQuery() {
            return (this.$route?.query?.s ?? '')
        },
        isDesktop() {
            return this.tabletLandscapeUp
        }
    },
    methods: {
        ...mapActions({
            fetchHints: 'search/fetchHints'
        }),
        ...mapMutations({
            setSearchHints: 'search/SET_SEARCH_HINTS'
        }),
        async getLastQuery() {
            this.showPropositions = true;
            await this.$axios.get(`${API.main}/search/customer-suggester`, {
                params: {
                    limit: 10
                }
            }).then(function (response) {
                if (response.data && response.data.length !== 0) {
                    this.propositionResult = (response?.data ?? {})
                }
            }.bind(this)).catch();
        },
        onFocus() {
            this.focused = true;
            this.$emit('focus');
            this.getLastQuery()
        },
        onBlur() {
            this.focused = false;
            this.showPropositions = false;
            this.$emit('blur');
            this.cleanHistory();
        },
        onEnter(input) {
            this.focused = false;
            this.$refs.search.blur()
            let query = input && typeof input === String ? input : this.input
            const url = `${this.localePath('/search')}${this.$i18n.locale === 'ua'? '' : `/${this.$i18n.locale}`}`
            if (query.length >= 3) {
                this.$router.push(`${url}?s=${query}`)
            } else {
                this.$router.push(`/search-empty?s=${query}`)
            }
        },
        onPopUpSelect(input) {
            this.onEnter(input)
            this.$emit('search', {
                resolve() {},
                value: input
            });
            this.setLocalstorage(input);
            this.cleanHistory(true);
        },
        onClear() {
            this.input = '';
        },
        onSearchButtonClick() {
            this.isPopUp = true;
            this.getLastQuery();
        },
        cleanHistory(force) {
            this.searchHistory = [];
            if (!this.isPopupFoolSize || force) {
                this.isPopUp = false;
            }
        },
        onPopupClose() {
            this.showPropositions = false;
            this.cleanHistory(true);
        },
        getLocalstorage() {
            const storageField = localStorage.getItem(this.lsFieldName);
            if (storageField) {
                this.searchHistory = JSON.parse(storageField);
                this.isPopUp = true;
            } else if (!this.tabletLandscapeUp) {
                this.isPopUp = true;
            }
        },
        setLocalstorage(str) {
            const storageField = localStorage.getItem(this.lsFieldName);
            if (storageField) {
                let storageData = JSON.parse(storageField);
                const isDouble = storageData.find(item => item === str);
                if (!isDouble) {
                    storageData = [str, ...storageData];
                    const cutData = storageData.slice(0, 7);
                    const jsonArr = JSON.stringify(cutData);
                    localStorage.setItem(this.lsFieldName, jsonArr);
                }
            } else {
                const jsonArr = JSON.stringify([str]);
                localStorage.setItem(this.lsFieldName, jsonArr);
            }
        },
        onInput(input) {
            this.input = input.trim();
            if (typeof this.input === Object) {
                this.input = '';
            }
        },
        onInputSearch: debounce(function (input) {
            this.fetchHints(input);
        }, 500, { 'leading': false }),
    },
    watch: {
        nowRoute: {
            handler() {
                this.input = (this.$route?.query?.s ?? '');
            }
        },
        searchQuery: {
            handler(newVal) {
                this.input = newVal
            }
        },
        input: {
            handler(newVal){
                debouncedEsEvent(newVal);
            }
        },
    },
    mounted() {
        this.input = (this.$route?.query?.s ?? '');
    }
}
