
let requestsList = {}
export const state = () => ({
    topLinks: [],
    menu: [],
    counts: {
        favorite: 0,
        cart: 0,
    },
    uspHeaderData: [],
    uspData: {},
    activeMenuItemKey: '',
    hoveredMenuItemKey: '',

    showMobileMenu: false,
    mobileMenuActiveCategory: null,
    mobileMenuActiveColumn: null,
});

export const getters = {
    getFavoriteCount: ({counts}) => counts.favorite,
    getCartCount: ({counts}) => counts.cart,
    getTopLinks: ({topLinks}) => topLinks,
    getTopLinksCount: (state, {getTopLinks}) => getTopLinks?.length || 0,
    getMenu: ({menu}) => menu?.desktop || [],
    getMenuMobile: ({menu}) => menu?.mobile || [],
    getMobileMenuActiveCategory: ({mobileMenuActiveCategory}) => mobileMenuActiveCategory,
    getMobileMenuActiveColumn: ({mobileMenuActiveColumn}) => mobileMenuActiveColumn,
    getMobileMenuStatus: ({showMobileMenu}) => showMobileMenu,
    getUspHeaderData: ({uspHeaderData}) => uspHeaderData,
    getUspData: ({uspData}) => uspData,
    getIsMenuActive : ({activeMenuItemKey})=> activeMenuItemKey.trim() !== '',
    getActiveMenuItemKey : ({activeMenuItemKey})=> activeMenuItemKey,
    getHoveredMenuItemKey : ({hoveredMenuItemKey})=> hoveredMenuItemKey
};

let hoverItemTimeout = null;
export const actions = {
    setActiveItem({commit, getters}, key){
        commit(types.SET_HOVERED_MENU_ITEM, key);
        commit(types.SET_ACTIVE_MENU_ITEM_KEY,key)
    },
    setHoveredItem({commit, getters}, key){
        commit(types.SET_HOVERED_MENU_ITEM, key);
        !hoverItemTimeout && (hoverItemTimeout = setTimeout(()=> {
            clearTimeout(hoverItemTimeout);
            hoverItemTimeout = null;
            commit(types.SET_ACTIVE_MENU_ITEM_KEY, getters.getHoveredMenuItemKey)
        },350))
    },
    async fetchData({commit, dispatch}, lang) {
        const locale = lang || this.$i18n.locale;
        try {
            const menuData = dispatch('fetchMenuData', locale);
            const topLinksData = dispatch('fetchTopLinksData', locale);
            const uspHeader = dispatch('fetchUspLineData', locale);
            await Promise.all([menuData, topLinksData, uspHeader  ]);
        } catch (err) {
        }
    },
    async fetchMenuData({commit}, lang) {
        try {
            requestsList.fetchMenuData = this.$axios.CancelToken.source()
            await this.$axios.$get(`${API.main}/menu`, {
                cancelToken: requestsList.fetchMenuData.token
            }).then((data) => {
                commit(types.SET_MENU_DATA, data);
            });
        } catch (err) {
        }
    },
    async fetchTopLinksData({commit}, lang) {
        try {
            requestsList.fetchTopLinksData = this.$axios.CancelToken.source()
            await this.$axios.$get(`${API.main}/header`, {
                cancelToken: requestsList.fetchTopLinksData.token
            }).then((data) => {
                commit(types.SET_HEADER_TOP_LINKS, data);
            });
        } catch (err) {
        }
    },
    async fetchUspLineData({commit}, lang) {
        try {
            requestsList.fetchUspLineData = this.$axios.CancelToken.source()
            await this.$axios.$get(`${API.main}/usp-header`, {
                cancelToken: requestsList.fetchUspLineData.token
            }).then((data) => {
                commit(types.SET_USP_HEADER_ITEMS, data);
            });
        } catch (err) {
        }
    },

    setActiveMobileCategory({commit, getters}, data){
        commit(types.SET_ACTIVE_MOBILE_MENU_CATEGORY, data);
    },
    setActiveMobileColumn({commit, getters}, data){
        commit(types.SET_ACTIVE_MOBILE_MENU_COLUMN, data);
    },
    setMobileStatus({commit, dispatch}, data){
        commit(types.SET_ACTIVE_MOBILE_MENU, data);
        if (!data) {
            dispatch('setActiveMobileColumn', null);
            dispatch('setActiveMobileCategory', null);
        }
    },
};

export const mutations = {
    [types.SET_HOVERED_MENU_ITEM](state, key) {
        state.hoveredMenuItemKey = key
    },
    [types.SET_ACTIVE_MENU_ITEM_KEY](state, key) {
        state.activeMenuItemKey = key
    },
    [types.SET_MENU_DATA](state, data) {
        state.menu = data
    },
    [types.SET_HEADER_TOP_LINKS](state, data) {
        state.topLinks = data
    },
    [types.SET_HEADER_COUNTS](state, data) {
        state.counts = data
    },
    [types.SET_USP_HEADER_ITEMS](state, data) {
        state.uspHeaderData = data
    },
    [types.SET_USP_DATA](state, data) {
        state.uspData = data
    },
    [types.SET_HEADER_FAVORITE_COUNT](state, count) {
        state.counts.favorite = count;
    },
    [types.SET_HEADER_CART_COUNT](state, count) {
        state.counts.cart = count;
    },

    [types.SET_ACTIVE_MOBILE_MENU](state, data) {
        state.showMobileMenu = data
    },
    [types.SET_ACTIVE_MOBILE_MENU_CATEGORY](state, data) {
        state.mobileMenuActiveCategory = data
    },
    [types.SET_ACTIVE_MOBILE_MENU_COLUMN](state, data) {
        state.mobileMenuActiveColumn = data
    },
};
