//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CommonForm from "@/components/common/CommonForm";
import LazyFormRow from "@/components/common/LazyFormRow/index";
import popupWithForm from "@/mixins/popupWithForm";
export default {
    name: "SubscribeForm",
    mixins: [popupWithForm],
    components: {LazyFormRow, CommonForm},
    props: {
        source: {
            type: String,
            default: 'pop_up'
        }
    },
    methods: {
        successSubmit(result) {
            this.$ga.sendSimpleEvent('popup-subscription')
            let step = result.SubscriptionForm.code === 304 ? 3 : 2;
            this.$emit('changeStep', {
                step: step,
                message: result.SubscriptionForm.message
            })
            sessionStorage.setItem('canShowSubscribe', 2);
        }
    },
    computed: {
        lang() {
            return this.$i18n.locale
        },
        privacyLink() {
            return this.lang === 'ru' ? '/politika-konfidenczialnosti-programmy-loyalnosti-adidas-universe' : '/politika-konfidenczijnosti-programi-loyalnosti-adidas-universe'
        },
    }
}
