//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import icons from "~/assets/img/icons";
import SearchProposition from "@/components/pages/search/SearchProposition";

export default {
    name: "SearchPopup",
    components: {SearchProposition},
    props: {
        items: {
            type: Array,
            default: () => ([])
        },
        value: {
            type: String,
            default: ''
        },
        isHistory: {
            type: Boolean,
            default: false
        },
        isFoolSize: {
            type: Boolean,
            default: false
        },
        proposition: {
            type: Object,
            default: () => { return {} }
        }
    },
    data() {
        return {
            icons,
            words: null
        }
    },
    computed: {
        input: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        showAllText() {
            return `${this.$t('search.showAll')} "${this.input}"`
        },
        isFooter() {
            return !this.isHistory && this.isFoolSize && this.items && this.items.length > 0
        },
        marketItems() {
            return this.items.map(item => this.input ? item.replace(this.input, `<b>${this.input}</b>`) : item)
        },
        showClear() {
            return this.input.length > 0
        },
        body() {
            if (typeof document !== 'undefined') {
                return document.querySelector('body');
            } else {
                return false
            }
        }
    },
    methods: {
        onSelect(value) {
            this.$emit('select');
            this.onClose();
        },
        onFocus() {
            this.$emit('focus');
        },
        onInput(input) {
            this.input = input.trim();
        },
        onBlur() {
            this.$emit('blur');
        },
        onClose() {
            this.$emit('close');
        },
        onClear() {
            this.$emit('clear');
            this.setInputFocus();
            this.words = null
        },
        setInputFocus() {
            this.$refs.input.focus();
        },
        boldSubstr(value) {
            return value.toLowerCase().replace(new RegExp(this.input.toLowerCase(), 'g'), `<b>${this.input.toLowerCase()}</b>`);
        }
    },
    mounted() {
        this.setInputFocus();
        this.body && this.body.classList.add('v-popup-open');
        this.$liveChat && this.$liveChat.hide();
    },
    beforeDestroy() {
        this.body && this.body.classList.remove('v-popup-open');
        setTimeout(function () {
            this.$liveChat && this.$liveChat.show();
        }.bind(this), 600);
    },
    watch: {
        input: {
            async handler(val, oldVal) {
                if (val.length >= 3) {
                    await this.$axios.get(`${API.main}/search/suggester`, {
                        params: {
                            searchString: val,
                            limit: 10
                        }
                    }).then(function (response) {
                        this.words = (response?.data ?? [])
                    }.bind(this)).catch()
                } else {
                    this.words = null
                }
            }
        }
    }
}
