//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "SearchProposition",
    props: {
        query: {
            type: String,
            default: ''
        },
        result: {
            type: Object,
            default: () => { return {} },
        }
    },
    computed: {
        words() {
            return [];
        },
        items() {
            return (this?.result?.items ?? []);
        }
    },
    methods: {
        closePopup() {
            this.$emit('close')
        }
    }
}
